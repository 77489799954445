import { AwsRum, AwsRumConfig } from 'aws-rum-web';

import { logger, logError } from 'src/logger';

const appId = 'b78bed55-31d6-4dff-b99c-ab8785ed0167';
const appVersion = '1.0.0';
const appRegion = 'us-east-1';

// filter out errors from browser extensions and ResizeObserver's loop detection (which is generally benign)
// see:
// https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120
// https://github.com/WICG/resize-observer/issues/38#issuecomment-422126006
// referenced from: https://tiny.amazon.com/1c74my4ut
const ignoreErrorPatterns = [
  /chrome-extension/,
  /moz-extension/,
  /ResizeObserver loop/,
  // Outlook related error from Windows machines: https://tiny.amazon.com/1ahqrjibb/windows-rum-error
  /^Object Not Found Matching Id:(.+), MethodName:(.+), ParamCount:(.+)$/gm,
];
export const errorFilter = (event: ErrorEvent | PromiseRejectionEvent) => {
  const err =
    event instanceof ErrorEvent
      ? event
      : { error: event.reason ?? '', type: event.type, filename: '', message: event.reason ?? '' };
  return ignoreErrorPatterns.some(
    (errorPattern) =>
      errorPattern.test(err.error?.stack) || errorPattern.test(err.filename) || errorPattern.test(err.message)
  );
};

//  TODO: Add support for Cloudwatch Rum across preprod stages - https://app.asana.com/0/1206972973144714/1207623892232818/f
const rumConfigMap = new Map<string, AwsRumConfig>([
  [
    'prod',
    {
      sessionSampleRate: 1,
      guestRoleArn: 'arn:aws:iam::851725357035:role/SSSAshTrimWebsite-Prod-CloudWatchRumMonitorprodusea-YXJMU8kwNOVp',
      identityPoolId: 'us-east-1:b8b6d8b3-15e3-43c9-ab8a-3a58121c972e',
      endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
      telemetries: [
        'performance',
        'http',
        [
          'errors',
          {
            ignore: errorFilter,
            stackTraceLength: 1000,
          },
        ],
      ],
      allowCookies: true,
      enableXRay: false,
    },
  ],
]);

export default (stage: string) => {
  const rumConfig = rumConfigMap.get(stage);
  if (rumConfig) {
    try {
      const config: AwsRumConfig = {
        sessionSampleRate: rumConfig.sessionSampleRate,
        guestRoleArn: rumConfig.guestRoleArn,
        identityPoolId: rumConfig.identityPoolId,
        endpoint: rumConfig.endpoint,
        telemetries: rumConfig.telemetries,
        allowCookies: rumConfig.allowCookies,
        enableXRay: rumConfig.enableXRay,
      };

      // Create an AWS Rum monitor for Trim
      // eslint-disable-next-line no-new
      new AwsRum(appId, appVersion, appRegion, config);
      logger.info(`Cloudwatch Rum created successfully with config: ${JSON.stringify(config)}`);
    } catch (error: any) {
      // Swallow errors thrown during CloudWatch RUM web client initialization
      logError('UseRum#rumConfig', 'Error while initializing CloudWatch Rum monitor', error);
    }
  }
};
